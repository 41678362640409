import ErrorPage from '@root/drivers.root-enterprise.com/src/components/error-page';
import PropTypes from '@root/vendor/prop-types';
import React, { useEffect } from '@root/vendor/react';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import { StyleSheet } from '@root/core/src/utils/styles';

const InvalidCampaign = ({ campaignId }) => {
  const { trackEvent } = useAnalytics('INVALID_CAMPAIGN', false);

  useEffect(() => {
    trackEvent('VIEWED', { $campaign_public_id: campaignId ? campaignId : 'NO_CAMPAIGN_ID' });
  }, [campaignId, trackEvent]);

  const errorTextElement = (
    <>
      <h1 css={styles.title}>
        {'Something went wrong.'}
      </h1>
      <p>
        {'Contact your carrier for assistance.'}
      </p>
    </>
  );

  return (
    <ErrorPage
      errorTextElement={errorTextElement}
      testId={'invalid-campaign-error'}
    />
  );
};

export default InvalidCampaign;

const styles = StyleSheet.create({
  title: {
    marginTop: 25,
    fontSize: 24,
  },
});

InvalidCampaign.propTypes = {
  campaignId: PropTypes.string,
};
