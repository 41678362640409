import AppDownloadUnsuccessful from '@root/drivers.root-enterprise.com/src/components/app-download-unsuccessful';
import CampaignLanding from '@root/drivers.root-enterprise.com/src/scenes/campaign-landing';
import EmailConfirmationFailure from '@root/drivers.root-enterprise.com/src/components/email-confirmation-failure';
import InvalidCampaign from '@root/drivers.root-enterprise.com/src/scenes/invalid-campaign';
import ManualConfirmation from '@root/drivers.root-enterprise.com/src/scenes/manual-confirmation';
import React from '@root/vendor/react';
import STORE_MAPPINGS from '@root/drivers.root-enterprise.com/src/constants/parsnip-app-store-mappings';
import SignupConfirm from '@root/drivers.root-enterprise.com/src/scenes/signup-confirm';
import SmsConfirmationFailure from '@root/drivers.root-enterprise.com/src/components/sms-confirmation-failure';
import environment from '@root/core/src/utils/environment';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import { Redirect, Route, Switch } from '@root/core/src/components/route';
import { isAndroid, isIos } from '@root/core/src/utils/detect-mobile-browser';
import { useLocation } from '@root/vendor/react-router-dom';

const PublicRouter = () => {
  const { trackEvent } = useAnalytics('ROUTER', false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const campaignId = searchParams.get('campaign_id') || '';
  const cohortId = searchParams.get('cohort_id') || '';
  const code = searchParams.get('code') || '';
  const email = searchParams.get('email') || '';
  let redirectRoute;

  if (!campaignId) {
    redirectRoute = '/invalid';
  } else {
    redirectRoute = '/signup';
  }

  function redirectToStore(clientId, intendedRoute, analyticsProperties) {
    if (Object.keys(STORE_MAPPINGS).includes(clientId)) {
      const appStoreId = STORE_MAPPINGS[clientId].ios;
      const playStoreId = environment.environment === 'staging' ? STORE_MAPPINGS[clientId].android.staging : STORE_MAPPINGS[clientId].android.production;

      if (playStoreId && isAndroid()) {
        trackEvent(`REDIRECT_TO_PLAY_STORE_FROM_${intendedRoute}`, analyticsProperties);
        window.location.assign(`https://play.google.com/store/apps/details?id=${playStoreId}`); /* eslint root/prevent-use-of-window-location: 0 */
      } else if (appStoreId && isIos()) {
        trackEvent(`REDIRECT_TO_APP_STORE_FROM_${intendedRoute}`, analyticsProperties);
        window.location.assign(`https://apps.apple.com/us/app/id${appStoreId}?mt=8`); /* eslint root/prevent-use-of-window-location: 0 */
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  return (
    <Switch>
      <Route
        path={'/signup'}
        render={() => {
          return (
            <>
              <CampaignLanding
                campaignId={campaignId}
                email={email}
              />
            </>
          );
        }}
      />
      <Route
        path={'/invalid'}
        render={() => {
          return (
            <>
              <InvalidCampaign
                campaignId={campaignId}
              />
            </>
          );
        }}
      />
      <Route
        path={'/signup-confirm'}
        render={() => {
          return (
            <>
              <SignupConfirm />
            </>
          );
        }}
      />
      <Route
        path={'/clients/:clientId/drivers/confirmation'}
        render={({ match }) => {
          const clientId = match.params.clientId;
          if (!redirectToStore(clientId, 'EMAIL_CONFIRMATION', { $client_public_id: clientId })) {
            return (
              <EmailConfirmationFailure />
            );
          }
        }}
      />
      <Route
        path={'/clients/:clientId/drivers/cohort'}
        render={({ match }) => {
          const clientId = match.params.clientId;
          const analyticsProperties = {
            $driver_cohort_public_id: cohortId ? cohortId : 'NO_COHORT_ID',
            $client_public_id: clientId,
          };
          if (!redirectToStore(match.params.clientId, 'COHORT_LINK', analyticsProperties)) {
            return (
              <AppDownloadUnsuccessful
                analyticsPrefix={'COHORT_DOWNLOAD_UNSUCCESSFUL'}
                analyticsProperties={analyticsProperties}
              />
            );
          }
        }}
      />
      <Route
        path={'/clients/:clientId/drivers/campaign'}
        render={({ match }) => {
          const clientId = match.params.clientId;
          const analyticsProperties = {
            $campaign_public_id: campaignId ? campaignId : 'NO_CAMPAIGN_ID',
            $client_public_id: clientId,
          };
          if (!redirectToStore(match.params.clientId, 'CAMPAIGN_LINK', analyticsProperties)) {
            return (
              <AppDownloadUnsuccessful
                analyticsPrefix={'CAMPAIGN_DOWNLOAD_UNSUCCESSFUL'}
                analyticsProperties={analyticsProperties}
              />
            );
          }
        }}
      />
      <Route
        path={'/clients/:clientId/manual-confirmation'}
        render={({ match }) => {
          const clientId = match.params.clientId;
          return (
            <>
              <ManualConfirmation
                clientId={clientId}
                code={code}
              />
            </>
          );
        }}
      />
      <Route
        path={'/clients/:clientId/drivers/entry'}
        render={({ match }) => {
          const clientId = match.params.clientId;
          if (!redirectToStore(clientId, 'SMS_CONFIRMATION', { $client_public_id: clientId })) {
            return (
              <SmsConfirmationFailure />
            );
          }
        }}
      />
      <Redirect to={redirectRoute} />
    </Switch>
  );
};

export default PublicRouter;
