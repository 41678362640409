import AnalyticsService from '@root/core/src/services/analytics-service';
import NetworkService from '@root/core/src/services/network-service';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import { Colors } from '@root/core/src/utils/styles';
import { useEffect, useState } from '@root/vendor/react';
import { useHistory } from '@root/core/src/components/route';
import { validateParsnipCampaign } from '@root/drivers.root-enterprise.com/src/api/root-server';

export function useValidateCampaign({
  campaignId,
  ignoreDisabled = false,
  setIsLoading,
}) {
  const [campaignData, setCampaignData] = useState();
  const history = useHistory();
  const { trackEvent } = useAnalytics('VALIDATE_CAMPAIGN', false);

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await NetworkService.request(validateParsnipCampaign, { publicId: campaignId });

      if (data) {
        AnalyticsService.register({
          campaignId,
          clientId: data.clientPublicId,
          cohortId: data.cohortPublicId,
        });

        if (data.enabled || ignoreDisabled) {
          const buttonColor = '#' + data.buttonColorHexCode;
          const buttonTextColor = data.buttonTextColorHexCode ? '#' + data.buttonTextColorHexCode : Colors.white();

          setCampaignData({
            buttonColorHexCode: buttonColor,
            buttonTextColorHexCode: buttonTextColor,
            assetFilePrefix: data.assetFilePrefix,
            clientPublicId: data.clientPublicId,
            cohortPublicId: data.cohortPublicId,
          });

          setIsLoading(false);
        } else {
          trackEvent('DISABLED_CAMPAIGN', {
            $client_public_id: data.clientPublicId,
            $cohort_public_id: data.cohortPublicId,
            $campaign_public_id: campaignId,
          });
          history.push(`/clients/${data.clientPublicId}/drivers/campaign?campaign_id=${campaignId}`);
        }
      } else {
        history.push(`/invalid?campaign_id=${campaignId}`);
      }
    };

    fetchData();
  }, [campaignId, history, ignoreDisabled, setIsLoading, trackEvent]);

  return {
    ...campaignData,
  };
}
