import { Logos } from '@root/drivers.root-enterprise.com/src/assets/images/logos/client-logos';
import { RootError } from '@root-common/root-errors';

const LogoNames = Object.keys(Logos);

export const getLogo = (name) => {
  if (!LogoNames.includes(name)) {
    throw new RootError({
      message: `Unknown logo name: ${name}`,
      name: 'LogosError',
      fingerprint: ['LogosUnknownLogoName'],
    });
  }
  return Logos[name];
};
