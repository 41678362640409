import ErrorPage from '@root/drivers.root-enterprise.com/src/components/error-page';
import React from '@root/vendor/react';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import { StyleSheet } from '@root/core/src/utils/styles';

const SmsConfirmationFailure = () => {
  useAnalytics('SMS_CONFIRMATION_FAILURE');

  const errorTextElement = (
    <>
      <h1 css={styles.title}>
        {'SMS confirmation unsuccessful'}
      </h1>
      <p>
        {'Please confirm your sms invitation from your mobile device to activate your account.'}
      </p>
    </>
  );

  return (
    <ErrorPage
      errorTextElement={errorTextElement}
      testId={'sms-confirmation-failure'}
    />
  );
};

export default SmsConfirmationFailure;

const styles = StyleSheet.create({
  title: {
    marginTop: 25,
    fontSize: 24,
  },
});
