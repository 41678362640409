import Button from '@root/core/src/components/button';
import CSSTransition from '@root/vendor/react-transition-group/CSSTransition';
import NetworkService from '@root/core/src/services/network-service';
import React, { useEffect, useMemo, useState } from '@root/vendor/react';
import ReactLoading from 'react-loading';
import Responsive from '@root/core/src/utils/responsive';
import get from '@root/vendor/lodash/get';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import { AnimationStyleSheet, Colors, Shadows, StyleSheet, Theme } from '@root/core/src/utils/styles';
import { confirmParsnipDriver } from '@root/drivers.root-enterprise.com/src/api/root-server';
import { getLogo } from '@root/drivers.root-enterprise.com/src/utils/logos';
import { useHistory } from '@root/core/src/components/route';
import { useValidateCampaign } from '@root/drivers.root-enterprise.com/src/hooks/use-validate-campaign';

const SignupConfirm = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [showResendingHelper, setShowResendingHelper] = useState(false);

  const history = useHistory();
  const campaignId = get(history.location.state, 'campaignId', '');
  const email = get(history.location.state, 'email', '');
  const driverId = get(history.location.state, 'driverId', '');

  const { trackClick, trackEvent } = useAnalytics('SIGNUP_CONFIRMATION', false);

  const {
    assetFilePrefix,
    buttonColorHexCode,
    buttonTextColorHexCode,
    clientPublicId,
  } = useValidateCampaign({
    campaignId,
    setIsLoading,
    ignoreDisabled: true,
  }, []);

  const analyticsProperties = useMemo(() => {
    return {
      email,
      $driver_public_id: driverId,
      $campaign_public_id: campaignId ? campaignId : 'NO_CAMPAIGN_ID',
      $client_public_id: clientPublicId,
    };
  }, [email, driverId, campaignId, clientPublicId]);

  useEffect(() => {
    if (!driverId || !email) {
      trackEvent('MISSING_CREATED_DRIVER_STATE', analyticsProperties);
      history.push('/');
    } else {
      trackEvent('VIEWED', analyticsProperties);
    }
  }, [email, driverId, history, trackEvent, analyticsProperties]);

  useEffect(() => {
    if (showResendingHelper) {
      setTimeout(() => {
        setShowResendingHelper(false);
      }, 3000);
    }
  }, [setShowResendingHelper, showResendingHelper]);

  const onSubmitResend = async () => {
    trackClick('RESEND_EMAIL', analyticsProperties);
    setShowResendingHelper(true);

    const { error } = await NetworkService.request(confirmParsnipDriver, {
      driverId,
      clientId: clientPublicId,
    });

    if (error) {
      trackEvent('RESEND_EMAIL_FAILED', analyticsProperties);
      history.push('/invalid');
    }
  };

  return (
    <div data-testid={'signup-confirm'}>
      <div css={styles.container}>
        {isLoading ?
          <div css={styles.loading}>
            <ReactLoading
              color={'#a3a5a8'}
              height={500}
              type={'spinningBubbles'}
              width={300}
            />
          </div>
          :
          <div css={styles.innerContainer}>

            <div css={styles.imageContainer} >
              <img
                css={styles.image}
                src={getLogo(assetFilePrefix)}
              />
            </div>
            <div css={styles.bodyContainer}>
              <div css={styles.headerText}>
                {'Check your email on a mobile device for a confirmation link.'}
              </div>
            </div>
            <div css={styles.buttonContainer}>
              <Button
                css={{
                  backgroundColor: buttonColorHexCode,
                  color: buttonTextColorHexCode,
                }}
                onClick={onSubmitResend}
              >
                {'Resend email'}
              </Button>
            </div>
            <div css={styles.transition} >
              <CSSTransition
                classNames={animationClassNames}
                in={showResendingHelper}
                mountOnEnter={true}
                timeout={100}
                unmountOnExit={true}
              >
                <div css={styles.helper}>
                  {`Resending confirmation email to ${email}`}
                </div>
              </CSSTransition>
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default SignupConfirm;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 100,
  },
  innerContainer: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    ...Responsive.sm({
      minHeight: 450,
      maxWidth: 750,
      border: `1px solid ${Colors.gray30()}`,
      borderRadius: 5,
      margin: '0 15px',
      ...Shadows.cardShadow(),
    }),
  },
  bodyContainer: {
    textAlign: 'center',
    paddingBottom: 20,
  },
  headerText: {
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: 20,
    width: '90%',
    ...Responsive.sm({
      width: '80%',
    }),
    ...Theme.heading3(),
  },
  imageContainer: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    paddingTop: 40,
    paddingBottom: 40,

  },
  image: {
    width: '100%',
    ...Responsive.sm({
      width: '80%',
    }),
  },
  helper: {
    backgroundColor: 'rgba(0, 147, 205, 0.15)',
    borderRadius: 5,
    color: 'rgba(0,147,205,1)',
    marginTop: 20,
    paddingTop: 15,
    paddingBottom: 10,
    paddingLeft: 15,
    paddingRight: 15,
    textAlign: 'center',
  },
  transition: {
    paddingBottom: 10,
  },
});

const animationClassNames = AnimationStyleSheet.createClassNames({
  enterActive: {
    opacity: 0,
  },
  enterDone: {
    transition: 'all 400ms ease-in-out',
    opacity: 1,
  },
  exitActive: {
    opacity: 1,
  },
  exitDone: {
    opacity: 0,
  },
});
