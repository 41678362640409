import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';

function CheckCircleIcon({ iconStyles }) {
  return (
    <svg
      css={iconStyles}
      fill={'none'}
      height={'48'}
      viewBox={'0 0 49 48'}
      width={'49'}
      xmlns={'http://www.w3.org/2000/svg'}
    >
      <path
        clipRule={'evenodd'}
        d={'M2.04199 24C2.04199 11.8497 12.0969 2 24.5003 2C30.4566 2 36.169 4.31785 40.3808 8.44365C44.5925 12.5695 46.9587 18.1652 46.9587 24C46.9587 36.1503 36.9037 46 24.5003 46C12.0969 46 2.04199 36.1503 2.04199 24ZM20.7466 32.3766C21.0317 32.3766 21.3051 32.265 21.5059 32.0667L36.4995 17.421L34.9809 15.9333L20.7573 29.8457L14.0198 23.2457L12.5012 24.7333L19.9873 32.0667C20.1881 32.265 20.4614 32.3766 20.7466 32.3766Z'}
        fillRule={'evenodd'}
      />
    </svg>
  );
}

CheckCircleIcon.propTypes = {
  iconStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
};

export default CheckCircleIcon;
