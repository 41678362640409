import PropTypes from '@root/vendor/prop-types';
import errorImage from '@root/drivers.root-enterprise.com/src/assets/web-error.png';
import { Colors, Shadows, StyleSheet } from '@root/core/src/utils/styles';

const ErrorPage = ({ errorTextElement, testId }) => {
  return (
    <div
      css={styles.container}
      data-testid={testId}
    >
      <div css={styles.innerContainer}>
        <img
          css={styles.mainImage}
          src={errorImage}
        />
        <div css={styles.textContainer}>
          {errorTextElement}
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 100,
  },
  innerContainer: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    minHeight: 450,
    maxWidth: 750,
    border: `1px solid ${Colors.gray30()}`,
    borderRadius: 5,
    margin: '0 15px',
    ...Shadows.cardShadow(),
  },
  mainImage: {
    height: 200,
    width: 200,
    marginTop: 50,
  },
  textContainer: {
    margin: '0 20px',
    textAlign: 'center',
  },
});

ErrorPage.propTypes = {
  errorTextElement: PropTypes.element.isRequired,
  testId: PropTypes.string.isRequired,
};
