import ServerUtils from '@root/core/src/api/server-utils';

export async function validateParsnipCampaign(params) {
  const { data } = await ServerUtils.unauthenticatedPost('/drive_science_api/parsnip_campaign/validate', {
    body: {
      ...params,
    },
    expectedResponse: 200,
    expectedErrorResponses: [404], // eslint-disable-line
  });

  return data;
}

export async function brandingForClient(params) {
  const { data } = await ServerUtils.unauthenticatedGet('/drive_science_api/clients/branding', {
    query: {
      ...params,
    },
    expectedResponse: 200,
    expectedErrorResponses: [404], /* eslint root/dont-allow-404-response: 0 */
  });

  return data;
}

export async function createParsnipDriver({
  campaignId, clientId, email,
}) {
  const { data } = await ServerUtils.unauthenticatedPost('/parsnip_mobile_api/drivers', {
    body: {
      driver: {
        campaignId,
        clientId,
        email,
      },
    },
    expectedResponse: [201],
    expectedErrorResponses: [404, 422],
  });

  return data;
}

export async function confirmParsnipDriver({ clientId, driverId }) {
  const { data } = await ServerUtils.unauthenticatedPost('/parsnip_mobile_api/drivers/confirm', {
    body: {
      driver: {
        clientId,
        driverId,
      },
    },
    expectedResponse: [201],
    expectedErrorResponses: [422],
  });

  return data;
}
