import PropTypes from '@root/vendor/prop-types';
import React, { useState } from '@root/vendor/react';
import ReactLoading from 'react-loading';
import Responsive from '@root/core/src/utils/responsive';
import SignupForm from '@root/drivers.root-enterprise.com/src/components/signup-form';
import { Colors, Shadows, StyleSheet, Theme } from '@root/core/src/utils/styles';
import { getLogo } from '@root/drivers.root-enterprise.com/src/utils/logos';
import { useValidateCampaign } from '@root/drivers.root-enterprise.com/src/hooks/use-validate-campaign';

const CampaignLanding = ({ campaignId, email }) => {
  const [isLoading, setIsLoading] = useState(true);

  const {
    assetFilePrefix,
    buttonColorHexCode,
    buttonTextColorHexCode,
    clientPublicId,
  } = useValidateCampaign({
    campaignId,
    setIsLoading,
  }, []);

  return (
    <div data-testid={'campaign-landing'}>
      <div css={styles.container}>
        {isLoading ?
          <div css={styles.loading}>
            <ReactLoading
              color={'#a3a5a8'}
              height={500}
              type={'spinningBubbles'}
              width={300}
            />
          </div>
          :
          <div css={styles.innerContainer}>
            <div css={styles.imageContainer} >
              <img
                css={styles.image}
                src={getLogo(assetFilePrefix)}
              />
            </div>
            <div css={styles.formContainer}>
              <div css={styles.headerText}>Enter your email to get started.</div>
              <SignupForm
                buttonColor={buttonColorHexCode}
                buttonTextColor={buttonTextColorHexCode}
                campaignId={campaignId}
                clientId={clientPublicId}
                email={email}
              />
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default CampaignLanding;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 100,
  },
  innerContainer: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    ...Responsive.sm({
      minHeight: 450,
      maxWidth: 750,
      border: `1px solid ${Colors.gray30()}`,
      borderRadius: 5,
      margin: '0 15px',
      ...Shadows.cardShadow(),
    }),
  },
  formContainer: {
    textAlign: 'center',
    paddingBottom: 20,
  },
  headerText: {
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: 20,
    width: '90%',
    ...Responsive.sm({
      width: '100%',
    }),
    ...Theme.heading3(),
  },
  imageContainer: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    paddingTop: 40,
    paddingBottom: 40,

  },
  image: {
    width: '100%',
    ...Responsive.sm({
      width: '80%',
    }),
  },
});

CampaignLanding.propTypes = {
  campaignId: PropTypes.string.isRequired,
  email: PropTypes.string,
};
