import ErrorPage from '@root/drivers.root-enterprise.com/src/components/error-page';
import PropTypes from '@root/vendor/prop-types';
import React, { useEffect } from '@root/vendor/react';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import { StyleSheet } from '@root/core/src/utils/styles';

const AppDownloadUnsuccessful = ({ analyticsPrefix, analyticsProperties = {} }) => {
  const { trackEvent } = useAnalytics(analyticsPrefix, false);

  useEffect(() => {
    trackEvent('VIEWED', analyticsProperties);
  }, [analyticsProperties, trackEvent]);

  const errorTextElement = (
    <>
      <h1 css={styles.title}>
        {'App download unsuccessful'}
      </h1>
      <p>
        {'Please open this link from your mobile device.'}
      </p>
    </>
  );

  return (
    <ErrorPage
      errorTextElement={errorTextElement}
      testId={'app-download-unsuccessful'}
    />
  );
};

export default AppDownloadUnsuccessful;

const styles = StyleSheet.create({
  title: {
    marginTop: 25,
    fontSize: 24,
  },
});

AppDownloadUnsuccessful.propTypes = {
  analyticsPrefix: PropTypes.string.isRequired,
  analyticsProperties: PropTypes.object,
};
