import AnalyticsService from '@root/core/src/services/analytics-service';
import AuthService from '@root/core/src/services/auth-service';
import ErrorSummary from '@root/core/src/components/error-summary';
import Input, { TextTypes } from '@root/core/src/components/input';
import LoaderButton from '@root/core/src/components/loader-button';
import NetworkService from '@root/core/src/services/network-service';
import PropTypes from '@root/vendor/prop-types';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import useForm from '@root/core/src/hooks/use-form';
import { StyleSheet } from '@root/core/src/utils/styles';
import { createParsnipDriver } from '@root/drivers.root-enterprise.com/src/api/root-server';
import { isRequired, isValidEmail } from '@root/core/src/utils/validators';
import { useEffect, useMemo, useState } from '@root/vendor/react';
import { useHistory } from '@root/vendor/react-router-dom';

const validations = [
  isRequired('email'),
  isValidEmail('email'),
];

const SignupForm = ({
  email = '',
  buttonColor,
  buttonTextColor,
  campaignId,
  clientId,
}) => {
  const [isError, setIsError] = useState(false);
  const form = useForm({
    validations,
    validateOnBlur: false,
    initialValues: {
      email,
    },
  });
  const history = useHistory();
  const { trackClick, trackEvent } = useAnalytics('SIGNUP_CAMPAIGN');
  const analyticsProperties = useMemo(() => {
    return {
      $campaign_public_id: campaignId,
      $client_public_id: clientId,
    };
  }, [campaignId, clientId]);

  useEffect(() => {
    trackEvent('VIEWED', {
      email,
      ...analyticsProperties,
    });
  }, [analyticsProperties, campaignId, email, trackEvent]);

  const submit = async (e) => {
    e.preventDefault && e.preventDefault();
    const formEmail = form.getFieldProps('email').value;

    if (formEmail) {
      trackClick('SUBMITTED_EMAIL', {
        email: formEmail,
        ...analyticsProperties,
      });

      const { data } = await NetworkService.request(createParsnipDriver, {
        email: formEmail,
        campaignId,
        clientId,
      });

      if (data) {
        AnalyticsService.register({
          driverId: data.driverId,
          email: formEmail,
        });

        const signupResult = {
          campaignId,
          driverId: data.driverId,
          email: formEmail,
        };

        AuthService.setCurrentUserContext({ userId: data.driverId });
        trackEvent('DRIVER_CREATED', {
          email: formEmail,
          $driver_public_id: data.driverId,
          ...analyticsProperties,
        });
        history.push('/signup-confirm', signupResult);
      } else {
        trackEvent('EMAIL_SUBMISSION_FAILED', {
          email: formEmail,
          ...analyticsProperties,
        });
        setIsError(true);
      }
    }
  };

  let errorSummary = null;

  if (isError) {
    errorSummary = (
      <ErrorSummary
        heading={'There was an issue creating your account.'}
        message={'Please try again.'}
      />
    );
  }

  return (
    <div
      css={styles.container}
      data-testid={'signup-form'}
    >
      <form
        onSubmit={form.createSubmitHandler(submit)}
      >
        {errorSummary}
        <div css={styles.inputWrapper}>
          <Input
            autoCapitalize={'off'}
            id={'email'}
            inputType={TextTypes.TEXT}
            label={'Email'}
            {...form.getFieldProps('email')}
          />
        </div>
        {email && email === form.values.email &&
          <div css={styles.helper}>
            {'Autofilled with the email on file.'}
          </div>
        }
        <LoaderButton
          isLoading={form.submitting}
          loadingText={'Sending...'}
          styles={{
            backgroundColor: buttonColor,
            color: buttonTextColor,
          }}
        >
          Create account
        </LoaderButton>
      </form>
    </div>
  );
};

export default SignupForm;

const styles = StyleSheet.create({
  container: {
    width: 380,
  },
  inputWrapper: {
    marginBottom: 10,
  },
  helper: {
    backgroundColor: 'rgba(0, 147, 205, 0.15)',
    borderRadius: 5,
    color: 'rgba(0,147,205,1)',
    marginTop: -20,
    paddingTop: 15,
    paddingBottom: 10,
    paddingLeft: 15,
    textAlign: 'left',
  },
});

SignupForm.propTypes = {
  buttonColor: PropTypes.string.isRequired,
  buttonTextColor: PropTypes.string.isRequired,
  campaignId: PropTypes.string.isRequired,
  clientId: PropTypes.string.isRequired,
  email: PropTypes.string,
};
