import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';

function LinkIcon({ iconStyles }) {
  return (
    <svg
      css={iconStyles}
      fill={'none'}
      height={'24'}
      viewBox={'0 0 24 24'}
      width={'24'}
      xmlns={'http://www.w3.org/2000/svg'}
    >
      <path
        d={'M20.296 10.8679L16.7603 14.4036L15.6997 13.3429L19.2354 9.8072C20.6782 8.36434 20.6782 6.025 19.2354 4.58214C17.7925 3.13928 15.4532 3.13929 14.0103 4.58214L10.4746 8.11785L9.41395 7.05719L12.9497 3.52148C14.9783 1.49284 18.2674 1.49284 20.296 3.52148C22.3247 5.55013 22.3247 8.83921 20.296 10.8679Z'}
      />
      <path
        d={'M3.52148 12.9501L7.05719 9.41443L8.11786 10.4751L4.58214 14.0108C3.13929 15.4537 3.13928 17.793 4.58214 19.2359C6.025 20.6787 8.36434 20.6787 9.80719 19.2358L13.3429 15.7001L14.4036 16.7608L10.8679 20.2965C8.83921 22.3252 5.55013 22.3252 3.52148 20.2965C1.49284 18.2679 1.49284 14.9788 3.52148 12.9501Z'}
      />
      <path
        d={'M15.3072 7.45007L7.45006 15.3072L8.51072 16.3679L16.3679 8.51073L15.3072 7.45007Z'}
      />
    </svg>
  );
}

LinkIcon.propTypes = {
  iconStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
};

export default LinkIcon;
