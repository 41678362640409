import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import { StyleSheet, Theme } from '@root/core/src/utils/styles';

export const InstructionalImage = ({
  image, instructionText, testId = '',
}) => {
  return (
    <>
      <div
        css={styles.headerText}
        data-testid={testId}
      >
        {instructionText}
      </div>
      <div
        css={styles.imageContainer}
      >
        <img
          css={styles.image}
          src={image}
        />
      </div>
    </>
  );
};

const styles = StyleSheet.create({
  headerText: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '90%',
    textAlign: 'center',
    ...Theme.heading3(),
  },
  imageContainer: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    paddingTop: 40,
    paddingBottom: 40,

  },
  image: {
    width: '100%',
    ...Responsive.sm({
      width: '80%',
    }),
  },
});

InstructionalImage.propTypes = {
  image: PropTypes.string,
  instructionText: PropTypes.string,
  testId: PropTypes.string,
};
