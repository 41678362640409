export const strings = {
  manual_confirmation: {
    default: {
      mobile_header: 'First, copy your confirmation code using the button below:',
      mobile_instruction_one: 'Next, back in the app, look for the confirmation code link:',
      mobile_instruction_two: 'After that, paste the code from your clipboard and tap “Submit”',
      desktop_instruction: 'Please reopen this link on your phone to access and copy your confirmation code:',
      no_code_error: 'It looks like you have a malformed link. Please refer back to your email, or contact your carrier for further assistance.',
      button_tap_to_copy: 'Tap to copy code',
      button_copied: 'Code copied',
    },
  },
};
