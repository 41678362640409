import AnalyticsService from '@root/core/src/services/analytics-service';
import NetworkService from '@root/core/src/services/network-service';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import { Colors } from '@root/core/src/utils/styles';
import { brandingForClient } from '@root/drivers.root-enterprise.com/src/api/root-server';
import { useEffect, useState } from '@root/vendor/react';
import { useHistory } from '@root/core/src/components/route';

export function useBrandingForClient({
  clientId,
  setIsLoading,
}) {
  const [brandingData, setBrandingData] = useState();
  const history = useHistory();
  const { trackEvent } = useAnalytics('BRANDING_FOR_CLIENT', false);

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await NetworkService.request(brandingForClient, { publicId: clientId });

      if (data) {
        AnalyticsService.register({
          clientId,
        });

        const buttonColor = '#' + data.buttonColorHexCode;
        const buttonTextColor = data.buttonTextColorHexCode ? '#' + data.buttonTextColorHexCode : Colors.white();

        setBrandingData({
          buttonColorHexCode: buttonColor,
          buttonTextColorHexCode: buttonTextColor,
          assetFilePrefix: data.assetFilePrefix,
        });

        setIsLoading(false);
      } else {
        history.push(`/invalid?clientId=${clientId}`);
      }
    };

    fetchData();
  }, [clientId, history, setIsLoading, trackEvent]);

  return {
    ...brandingData,
  };
}
