import Button from '@root/core/src/components/button';
import CheckCircleIcon from '@root/drivers.root-enterprise.com/src/components/icons/check-circle-icon';
import LinkIcon from '@root/drivers.root-enterprise.com/src/components/icons/link-icon';
import PropTypes from '@root/vendor/prop-types';
import React, { useEffect, useState } from '@root/vendor/react';
import ReactLoading from 'react-loading';
import Responsive from '@root/core/src/utils/responsive';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import { Colors, Shadows, StyleSheet, Theme } from '@root/core/src/utils/styles';
import { InstructionalImage } from '@root/drivers.root-enterprise.com/src/components/instructional-image';
import { confirmationDesktopError, confirmationInstructionOne, confirmationInstructionTwo } from '@root/drivers.root-enterprise.com/src/assets/images/image-index';
import { getLogo } from '@root/drivers.root-enterprise.com/src/utils/logos';
import { isAndroid, isIos } from '@root/core/src/utils/detect-mobile-browser';
import { strings } from '@root/drivers.root-enterprise.com/src/constants/strings';
import { useBrandingForClient } from '@root/drivers.root-enterprise.com/src/hooks/use-branding-for-client';

const copyToClipboard = async (text) => {
  const el = document.createElement('textarea');
  el.value = text;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  await document.body.removeChild(el);
};

const ManualConfirmation = ({ clientId, code }) => {
  const { trackEvent } = useAnalytics('MANUAL_CONFIRMATION', false);

  const manualConfirmationStrings = strings.manual_confirmation.default;
  const [linkCopied, setLinkCopied] = useState(false);
  const [noCode] = useState(!code);
  const [isLoading, setIsLoading] = useState(true);

  const {
    assetFilePrefix,
    buttonColorHexCode,
    buttonTextColorHexCode,
  } = useBrandingForClient({
    clientId,
    setIsLoading,
  }, []);

  const onCopyButtonPressed = async () => {
    trackEvent('COPIED', {
      $client_public_id: clientId ? clientId : 'NO_CLIENT_ID',
      $code: code ? code : 'NO_CODE',
    });

    await navigator.clipboard?.writeText(`code=${code}`) || copyToClipboard(`code=${code}`);

    setTimeout(() => {
      setLinkCopied(false);
    }, 4000);
    setLinkCopied(true);
  };

  useEffect(() => {
    trackEvent('VIEWED', {
      $client_public_id: clientId ? clientId : 'NO_CLIENT_ID',
      $code: code ? code : 'NO_CODE',
    });
  }, [clientId, code, trackEvent]);

  const noCodeError = () => {
    return (
      <div data-testid={'no-code-in-url'}>
        <p>{manualConfirmationStrings.no_code_error}</p>
      </div>
    );
  };

  const pageContents = () => {
    if (isAndroid() || isIos()) {
      return (
        <>
          <div css={styles.headerText}>{manualConfirmationStrings.mobile_header}</div>
          {submitButton()}
          <InstructionalImage
            image={confirmationInstructionOne}
            instructionText={manualConfirmationStrings.mobile_instruction_one}
          />
          <InstructionalImage
            image={confirmationInstructionTwo}
            instructionText={manualConfirmationStrings.mobile_instruction_two}
          />
        </>
      );
    } else {
      return (
        <InstructionalImage
          image={confirmationDesktopError}
          instructionText={manualConfirmationStrings.desktop_instruction}
          testId={'manual-confirmation-desktop-view'}
        />
      );
    }
  };

  const submitButton = () => {
    let additionalStyle;

    if (!linkCopied) {
      additionalStyle = {
        backgroundColor: buttonColorHexCode,
        color: buttonTextColorHexCode,
        border: 'none',
        svg: {
          fill: buttonTextColorHexCode,
          width: 22,
        },
      };
    } else {
      additionalStyle = {
        backgroundColor: Colors.white(),
        color: Colors.black(),
        border: `3px solid ${buttonColorHexCode}`,
        svg: {
          stroke: Colors.black(),
          width: 22,
          strokeWidth: 3,
        },
      };
    }

    return (
      <div css={styles.buttonContainer}>
        <Button
          css={{
            width: 300,
            padding: 20,
            ...additionalStyle,
          }}
          iconComponent={!linkCopied ? LinkIcon : CheckCircleIcon}
          onClick={onCopyButtonPressed}

        >
          {!linkCopied ? manualConfirmationStrings.button_tap_to_copy : manualConfirmationStrings.button_copied}
        </Button>
      </div>
    );
  };

  return (
    <div data-testid={'manual-confirmation-landing'}>
      <div css={styles.container}>
        {isLoading ?
          <div css={styles.loading}>
            <ReactLoading
              color={'#a3a5a8'}
              height={500}
              type={'spinningBubbles'}
              width={300}
            />
          </div>
          :
          <div css={styles.innerContainer}>
            <div
              css={styles.imageContainer}
              data-testid={'manual-confirmation-logo'}
            >
              <img
                css={styles.image}
                src={getLogo(assetFilePrefix)}
              />
            </div>
            {noCode ? noCodeError() : pageContents()}
          </div>
        }
      </div>
    </div>

  );
};

export default ManualConfirmation;

const styles = StyleSheet.create({
  buttonContainer: {
    marginBottom: 48,
  },
  container: {
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 35,
  },
  headerText: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '90%',
    textAlign: 'center',
    ...Theme.heading3(),
  },
  innerContainer: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    width: '90%',
    ...Responsive.sm({
      minHeight: 450,
      maxWidth: 750,
      border: `1px solid ${Colors.gray30()}`,
      borderRadius: 5,
      margin: '0 15px',
      ...Shadows.cardShadow(),
    }),
  },
  imageContainer: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    paddingTop: 40,
    paddingBottom: 40,

  },
  image: {
    width: '100%',
    ...Responsive.sm({
      width: '80%',
    }),
  },
});

ManualConfirmation.propTypes = {
  clientId: PropTypes.string,
  code: PropTypes.string,
};
